import useToolsCards from 'hooks/useToolsCards';
import { filterPostsFromSplashAndToolbox } from 'components/Page/Webinar/utils/postsUtils';

const toolboxCardSections = (data) => {
  const toolCards = useToolsCards();
  const { webinarAd, articleAd, podcastAd, guidesAd } = data.contentfulContentHub;

  const sectionsDataTools = toolCards.map((item) => {
    return {
      excerpt: { excerpt: item.description.description },
      slug: item.ctaLink,
      title: item.title,
      __typename: 'ContentfulTools',
      featuredImage: item.cover,
      backColor: item.backColor,
    };
  });

  return [
    {
      category: 'Webinars',
      data: filterPostsFromSplashAndToolbox(data.allContentfulWebinar?.nodes),
      ad: webinarAd,
    },
    {
      category: 'Articles',
      data: data.allArticle?.nodes,
      ad: articleAd,
    },
    {
      category: 'Industry Insights',
      data: data.allIndustryInsights?.nodes,
    },
    {
      category: 'Podcasts',
      data: data.allContentfulPodcast?.nodes,
      ad: podcastAd,
    },
    {
      category: 'Tools',
      data: sectionsDataTools,
    },
    {
      category: 'Guides',
      data: data.allContentfulGatedContentPage?.nodes,
      ad: guidesAd,
    },
  ];
};

export default toolboxCardSections;
