import useToolboxImages from 'hooks/useToolboxImages';

const toolboxLogos = () => {
  const pageImages = useToolboxImages();

  const getImageUrl = (internalName) => {
    return pageImages[internalName]?.image?.file?.url;
  };

  return {
    articlesBlackLogo: getImageUrl('blog-mark'),
    articlesLogo: getImageUrl('blog-mark-white'),
    podcastBlackLogo: getImageUrl('podcast-mark'),
    podcastLogo: getImageUrl('podcast-mark-white'),
    toolboxLogo: getImageUrl('toolbox-mark-white'),
    toolsBlackLogo: getImageUrl('tools-mark'),
    toolsLogo: getImageUrl('tools-mark-white'),
    webinarBlackLogo: getImageUrl('webinar-mark'),
    webinarLogo: getImageUrl('webinar-mark-white'),
    downloadBlackLogo: getImageUrl('download-mark'),
    downloadLogo: getImageUrl('download-mark-white'),
    growthSeriesLogo: getImageUrl('toolbox-growth-series-icon'),
  };
};

export default toolboxLogos;
