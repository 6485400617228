import styled from 'styled-components';
import Slider from 'react-slick';
import HyperLink from 'components/Core/HyperLink';
import { SectionLayout } from 'components/Sections/SectionLayout';

export const HeroSlider = styled(Slider)`
  .slick-dots {
    button {
      width: 40px;
      height: 40px;
      &:before {
        font-size: var(--font-size-13);
        color: var(--grey-2);
        opacity: 1;
      }
    }
    .slick-active {
      button {
        &:before {
          color: var(--titan-blue-3);
        }
      }
    }
  }
`;

export const BannerWrapper = styled.div`
  max-width: 1200px;
  padding: 20px 19px;
  margin: 0 auto 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .banner-ad--link {
    width: 100%;
    margin: 0;

    img {
      width: 100%;
      border-radius: 10px;
    }
  }

  @media (max-width: 1283px) {
    margin: 0 45px 44px;
  }

  @media (max-width: 575px) {
    margin: 0 0 44px;

    .banner-ad--link {
      img {
        border-radius: 20px;
      }
    }
  }
`;

export const BannerPanelWrapper = styled.div`
  max-width: 1219px;
  padding: 20px 28px;
  margin: 0 auto 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > div {
    width: 100%;
    margin: 0;
    border-radius: 10px;
  }

  @media (max-width: 1283px) {
    margin: 0 30px 44px;
  }

  @media (max-width: 991px) {
    & > div {
      margin: 0;
    }
  }

  @media (max-width: 575px) {
    margin: 0 0 60px;
    padding: 0 20px;
  }
`;

export const CardFooterButton = styled(HyperLink)`
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-700);
  line-height: var(--line-height-110);
  color: var(--titan-blue-6);
  padding: 20px 56px;
  background: var(--white);
  width: 100%;
  display: inline-block;
  text-align: center;
  &:hover {
    color: var(--titan-blue-6);
  }
`;

export const FeaturedContentHeroWrapper = styled.div`
  margin: 0 !important;
  height: 100%;
  max-width: 1296px;
  padding: 0 0 56px 0;

  .gatsby-image-wrapper {
    max-height: 441px;
    img {
      width: 100%;
    }
  }

  .content-panel {
    margin-top: 0;

    @media (max-width: 767px) {
      margin-top: 40px;
      flex: 1;
      position: relative;
    }
  }

  .cta-button {
    width: 339px;
    margin-top: 20px;

    @media (max-width: 1100px) {
      width: 250px;
    }
    @media (max-width: 800px) {
      margin-bottom: 24px;
    }
    @media (max-width: 767px) {
      width: 100%;
      position: absolute;
      bottom: 24px;
    }
  }
  .hero-kicker {
    margin-bottom: 16px;
    font-size: var(--font-size-13);
    color: white;
    text-transform: uppercase;
  }
  .hero-title {
    font-size: var(--font-size-28);
    color: white;
    line-height: var(--line-height-110);
    letter-spacing: var(--letter-spacing--0-03);
    font-weight: var(--font-weight-900);
    margin-bottom: 16px;
  }
  .hero-text {
    color: white;
    margin-bottom: 0;

    @media (max-width: 767px) {
      padding-bottom: 150px;
    }
  }

  .toolbox--hero-row {
    margin: 0 !important;
  }

  @media (max-width: 1199px) {
    padding: 50px 0 0 0;
  }

  @media (max-width: 767px) {
    padding: 0 18px;

    .toolbox--hero-row {
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      justify-content: flex-end;
    }

    .toolbox--hero-image {
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    padding: 0 20px;
    margin: 0 auto 20px;
  }
`;

export const FeatureCategoryText = styled.div`
  position: relative;

  p.font-overline-light {
    margin-bottom: 16px;
    color: white;
  }

  &:before {
    content: ' ';
    width: ${(props) => (props.isHovering ? '0' : '50px')};
    background: linear-gradient(to right, #22222200, #222222ff);
    position: absolute;
    height: 100%;
    right: 0;
  }
`;

export const ToolboxHeroSectionLayout = styled(SectionLayout)`
  .section-layout--inner-container {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;

      & > div {
        height: 100%;
      }
    }
  }

  @media (max-width: 1199px) {
    .section-layout--inner-container {
      padding-top: 60px;
    }
  }

  @media (max-width: 767px) {
    .section-layout--inner-container {
      padding: 50px 0;
    }
  }

  @media (max-width: 575px) {
    .section-layout--inner-container {
      padding: 50px 0 55px;
    }
  }
`;

export const BadgesWrapper = styled.div`
  max-width: 1200px;
  padding: 0 0 0 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 60px;

  .badge-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 25px;
    padding: 10px 0;

    img {
      margin-right: 11px;
    }
  }

  @media (max-width: 1199px) {
    padding: 0 8px;
  }

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 767px) {
    .badge-item {
      margin: 0 12px;
    }
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  a {
    color: rgba(0, 0, 0, 0.85);
  }

  @media (max-width: 860px) {
    margin-top: 16px;
  }

  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
`;

export const SubNavContainer = styled.div`
  height: 64px;
  background-color: var(--darkest);
  justify-content: center;
  display: flex;
  width: 100%;
  z-index: 999;
  position: relative;
`;

export const SubNavMobileNav = styled.div`
  background: var(--darkest);
  height: ${(props) => (props.isOpen ? '100vh' : 0)};
  left: 0;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  position: absolute;
  top: ${({ simpleHeader }) => (simpleHeader ? '56px' : '64px')};
  width: 100vw;
  z-index: var(--z-toolbox-subnav) !important;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  ul {
    display: none;
    padding: 50px 28px;

    @media (max-width: 1199px) {
      display: block;
      display: ${(props) => (props.isOpen ? 'block' : 'none')};
    }
  }

  li {
    color: white;
    display: block;
    list-style: none;
    margin: 0 auto 20px auto;
    width: max-content;

    .toolbox-sticky-subnav--item-icon {
      width: 30px;
    }

    p {
      font-size: var(--font-size-18);
      width: 90px;
      margin-left: 5px;
    }
  }

  @media (max-width: 1199px) {
    display: block;
  }
`;
