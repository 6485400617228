import React from 'react';
import { Col, Row } from 'components/Core/Grid';
import Text from 'components/Core/Text';
import contentfulRichTextOptions from 'components/RichText/options/contentfulRichTextOptions';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import ContentfulCallToAction from 'components/Contentful/ContentfulCallToAction';
import HeroContextualCTA from 'components/Contentful/ContentfulDynamicHero/HeroContextualCTA';
import SimpleSocialProof from 'components/Sections/SocialProofSection/Simple';

import { StackedConversionPanelContainer, StackedConversionPanelInnerContainer } from './styles';

const StackedConversionPanel = ({ title, subhead, emailCaptureCta, cta, socialProof }) => {
  const subheadProps = {
    color: 'white',
  };

  return (
    <StackedConversionPanelContainer data-testid="stacked-conversion-panel">
      <StackedConversionPanelInnerContainer>
        <Row justify="center" className="content-container">
          <Col className="stacked-cp--title" xs={24} md={18} lg={14}>
            <Text color="White" type="h2" size="h1">
              {title}
            </Text>
          </Col>
          <Col className="stacked-cp--description" xs={24} md={18} lg={15}>
            {subhead && renderRichText(subhead, contentfulRichTextOptions(subheadProps))}
          </Col>
          {emailCaptureCta && (
            <Col xs={24} sm={20} md={18} lg={16}>
              <HeroContextualCTA
                content={emailCaptureCta}
                fullWidth
                ctaPosition="emailcapture-footer"
              />
            </Col>
          )}
          {cta && (
            <Col xs={24} sm={12} md={8} lg={5}>
              {cta && <ContentfulCallToAction {...cta} />}
            </Col>
          )}
          {socialProof && (
            <Col className="stacked-cp--social-proof" xs={24} md={18} lg={12}>
              <SimpleSocialProof theme="light" />
            </Col>
          )}
        </Row>
      </StackedConversionPanelInnerContainer>
    </StackedConversionPanelContainer>
  );
};

export default StackedConversionPanel;
