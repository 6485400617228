import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

export const StackedConversionPanelContainer = styled.div`
  .stacked-cp--title h1 {
    margin: 0;
  }
  .stacked-cp--description p {
    margin: 20px 0 0;
  }
  .stacked-cp--social-proof .simple-social-proof-section--container {
    & > div {
      justify-content: center;
      margin: 20px auto 0;
    }
  }
`;

export const StackedConversionPanelInnerContainer = styled(SectionLayout)`
  text-align: center;
  background: var(--dark);

  .section-layout--inner-container {
    padding: 120px 28px;
    @media (max-width: 1024px) {
      padding-top: 80px;
    }
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }
  .MuiGrid-container,
  .content-container {
    flex-direction: column;
  }
  .MuiGrid-item {
    margin: 0 auto;
  }
  .data-layer-form .text-field-wrapper .text-field-input {
    box-shadow: none;
  }
  .button--button {
    margin-top: 30px;
    width: 100%;
  }
`;
