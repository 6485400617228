import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/Core/Button';
import ModalMarketingForm from 'components/Form/ModalMarketingForm';

const SimpleTextButtonSection = ({
  title,
  subHead,
  description,
  buttonTitle,
  buttonLinkTo,
  utm,
  useModalForm,
  modalForm,
}) => {
  const [search, setSearch] = useState(undefined);
  const [isModalOpen, toggleIsModalOpen] = useState(false);

  useEffect(() => {
    setSearch(window?.location?.search);
  }, []);

  return (
    <Container data-testid="simple-text-button-section">
      <div className="section-text-button--container">
        {subHead && <p className="section-text-button--small-title">{subHead}</p>}
        <h2 className="section-text-button--title font-h2">{title}</h2>
        {description && <p className="section-text-button--description">{description}</p>}
        {useModalForm ? (
          <Button
            dataPosition="Conversion Panel - Stacked CTA"
            className="section-text-button--button"
            type="primary"
            shadow={false}
            onClick={() => toggleIsModalOpen(true)}
          >
            {buttonTitle}
          </Button>
        ) : (
          <Button
            dataPosition="Conversion Panel - Stacked CTA"
            to={`${buttonLinkTo}${utm && search ? search : ''}`}
            className="section-text-button--button"
            type="primary"
            shadow={false}
          >
            {buttonTitle}
          </Button>
        )}
      </div>

      {useModalForm && (
        <ModalMarketingForm
          isModalOpen={isModalOpen}
          onModalCancel={() => toggleIsModalOpen(false)}
          inlineMarketingForm={modalForm}
          onFormSuccess={() => toggleIsModalOpen(false)}
        />
      )}
    </Container>
  );
};

SimpleTextButtonSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundImg: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonLinkTo: PropTypes.string,
};

const Container = styled.div`
  padding: 0 20px;
  background: var(--dark);
  background-repeat: no-repeat;
  background-size: cover;

  .section-text-button--container {
    padding: 80px 0;
    max-width: 990px;
    margin: 0 auto;
    text-align: center;

    .section-text-button--small-title {
      color: var(--grey-3);
      font-size: var(--font-size-16);
      line-height: var(--line-height-110);
      font-weight: var(--font-weight-900);
      letter-spacing: var(--letter-spacing-0-05);
    }

    .section-text-button--title {
      color: var(--white);
    }

    .section-text-button--button {
      line-height: var(--line-height-110);
      padding: 20px 24px;
      margin-top: 40px;
    }
  }

  @media (max-width: 767px) {
    .section-text-button--container {
      padding: 40px 0;
      max-width: 590px;

      .section-text-button--button {
        font-size: var(--font-size-16);
        padding: 15px 24px;
        margin-top: 24px;
      }
    }
  }
`;

export default SimpleTextButtonSection;
