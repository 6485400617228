import { graphql, useStaticQuery } from 'gatsby';

const useToolsCards = () => {
  const { allContentfulTool } = useStaticQuery(graphql`
    query {
      allContentfulTool(
        filter: { displayTool: { eq: true } }
        sort: { order: ASC, fields: internalName }
      ) {
        edges {
          node {
            ...ToolCardMain
          }
        }
      }
    }
  `);

  const toolCards = allContentfulTool.edges.map((item) => ({
    __typename: 'ContentfulCard',
    title: item.node.internalName,
    description: { description: item.node?.excerpt?.excerpt || '' },
    cover: item.node.cardImage?.image || undefined,
    ctaText: 'Use Tool Now',
    ctaLink: `${item.node.seo?.pageUrl || ''}`,
    backColor: item.node.toolboxCardColor,
    industries: item.node?.seo?.taxonomy?.industries,
  }));

  return toolCards;
};

export default useToolsCards;
