export const getPostDate = (post) => new Date(post.webinarUTCDate);

const sortPostsLatestToNewest = (posts) => posts.sort((a, b) => getPostDate(b) - getPostDate(a));

const sortPostsNewestToLatest = (posts) => posts.sort((a, b) => getPostDate(a) - getPostDate(b));

export const getFeaturedPost = (posts) => {
  const sortedPosts = sortPostsNewestToLatest(posts);

  const featuredUpcomingPost = sortedPosts.filter((post) => {
    const postDate = getPostDate(post);

    return postDate > new Date();
  });

  const onDemandPost = getOnDemandPosts(posts)[0];

  return featuredUpcomingPost[0] || onDemandPost;
};

export const getUpcomingPosts = (posts) => {
  const sortedUpcomingPosts = sortPostsNewestToLatest(posts).filter((post) => {
    const postDate = getPostDate(post);
    const postDatePlusThirtyMins = new Date(new Date(postDate.getTime() + 30 * 60000));

    return postDatePlusThirtyMins > new Date();
  });

  return sortedUpcomingPosts.filter((post) => post !== getFeaturedPost(posts));
};

export const getOnDemandPosts = (posts) => {
  return sortPostsLatestToNewest(posts).filter(
    (post) => getPostDate(post) < new Date(new Date() - 30 * 60000),
  );
};

export const isLive = (post) => {
  const postDate = getPostDate(post);
  const postDatePlusThirtyMins = new Date(new Date(postDate.getTime() + 30 * 60000));

  const isLive = postDate < new Date() && new Date() < postDatePlusThirtyMins;

  return isLive;
};

export const isUpcomingPost = (post) => getPostDate(post) > new Date();

export const filterPostsFromSplashAndToolbox = (posts) => {
  return posts.filter((post) => post.removeFromSplashPage !== true);
};

export const removeFeatureAndSubscribePosts = (posts) => {
  const featuredPost = getFeaturedPost(posts);

  return posts.filter((post) => post.id !== featuredPost.id && post.onDemandPage);
};
