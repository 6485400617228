import React from 'react';
import SimpleTextButtonSection from 'components/Sections/SimpleTextButtonSection';
import InlineCta from 'components/Core/InlineCta';
import StackedConversionPanel from './StackedConversionPanel';

const ContentfulConversionPanel = ({
  anchorId,
  style,
  headline,
  headingKicker,
  subhead,
  cta,
  emailCaptureCta,
  socialProof,
  backgroundImage,
}) => {
  const { buttonLabel, carryUtmParameters, modalForm, url, useModalForm, color } = cta || {};

  return style === 'Split' ? (
    <InlineCta
      buttonColor={color}
      buttonLinkTo={url}
      buttonTitle={buttonLabel}
      dataPosition="Conversion Panel"
      description={subhead}
      formType="Email Capture"
      id={anchorId}
      modalForm={modalForm}
      subHead={headingKicker}
      title={headline}
      useModalForm={useModalForm}
      utm={carryUtmParameters}
      theme="light"
    />
  ) : style === 'Stacked' ? (
    <SimpleTextButtonSection
      buttonLinkTo={url}
      buttonTitle={buttonLabel}
      dataPosition="Conversion Panel"
      id={anchorId}
      modalForm={modalForm}
      subHead={headingKicker}
      title={headline}
      useModalForm={useModalForm}
      utm={carryUtmParameters}
    />
  ) : style === 'Final CTA' ? (
    <StackedConversionPanel
      title={headline}
      subhead={subhead}
      emailCaptureCta={emailCaptureCta}
      cta={cta}
      socialProof={socialProof}
      backgroundImage={backgroundImage}
      id={anchorId}
    />
  ) : null;
};

export default ContentfulConversionPanel;

ContentfulConversionPanel.defaultProps = {
  style: 'None',
  headline: '',
  headingKicker: '',
  buttonTitle: '',
  buttonLinkTo: '',
};
