import toolboxLogos from 'components/Page/Toolbox/data/toolboxLogos';

const toolboxSections = () => {
  const {
    articlesLogo,
    podcastLogo,
    toolsLogo,
    webinarLogo,
    articlesBlackLogo,
    podcastBlackLogo,
    toolsBlackLogo,
    webinarBlackLogo,
  } = toolboxLogos();

  return {
    Webinars: {
      title: 'Webinars',
      anchor: 'Webinars',
      src: webinarLogo,
      src_black: webinarBlackLogo,
      alt: 'Webinar logo',
      viewAll: '/webinar',
    },
    Articles: {
      title: 'Articles',
      anchor: 'Articles',
      src: articlesLogo,
      src_black: articlesBlackLogo,
      alt: 'Article logo',
      viewAll: '/blog',
    },
    'Industry Insights': {
      title: 'Industry Insights',
      anchor: 'Industry Insights',
      viewAll: '/blog?topic=Industry Insights',
    },
    Podcasts: {
      title: 'Podcasts',
      anchor: 'Podcasts',
      src: podcastLogo,
      src_black: podcastBlackLogo,
      alt: 'Podcast logo',
      viewAll: '/podcasts',
    },
    Tools: {
      title: 'Tools',
      anchor: 'Tools',
      src: toolsLogo,
      src_black: toolsBlackLogo,
      alt: 'Tools logo',
      viewAll: '/tools',
    },
    Guides: {
      title: 'Guides',
      anchor: 'Guides',
      src: articlesLogo,
      src_black: articlesBlackLogo,
      alt: 'Guides logo',
      viewAll: '/guides',
    },
  };
};

export default toolboxSections;
