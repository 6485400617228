import React from 'react';
import ContentfulConversionPanel from 'components/Contentful/ContentfulConversionPanel';
import Ad from 'components/Core/Ad';

import { BannerWrapper, BannerPanelWrapper } from '../styles';

const BannerAd = ({ bannerContent }) => {
  const type = bannerContent.__typename;

  if (type === 'ContentfulAd') {
    const AdContent = (image) => (
      <Ad
        url={bannerContent.url}
        image={image}
        modalForm={bannerContent.modalForm}
        data-promocontent={bannerContent.contentInformation || ''}
        data-promoposition="toobox_banner"
        data-position="inline"
        className="ST_promo banner-ad--link"
      />
    );

    return (
      <>
        <BannerWrapper className="min-sm">
          {AdContent(bannerContent?.image?.image?.file?.url)}
        </BannerWrapper>
        {bannerContent.image?.imageMobile && (
          <BannerWrapper className="max-sm">
            {AdContent(bannerContent?.image?.imageMobile?.file?.url)}
          </BannerWrapper>
        )}
      </>
    );
  }

  if (type === 'ContentfulConversionPanel') {
    return (
      <BannerPanelWrapper>
        <ContentfulConversionPanel {...bannerContent} />
      </BannerPanelWrapper>
    );
  }

  return <></>;
};

export default BannerAd;
