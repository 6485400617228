import React from 'react';
import Cards from 'components/Core/Cards';

const SalaryLicensesContent = ({ selectedPost }) => {
  if (!selectedPost) {
    return null;
  }

  return (
    <div>
      <Cards
        type="Image"
        bodyType="blog"
        sideCover
        coverImage={selectedPost.featuredImage.image}
        category={selectedPost.seo?.taxonomy?.industries?.join(', ') || null}
        title={selectedPost.title}
        description={selectedPost.excerpt?.excerpt}
        ctaTitle="Learn more"
        ctaLink={`${selectedPost.seo.pageUrl}`}
      />
    </div>
  );
};

export default SalaryLicensesContent;
