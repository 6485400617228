import { graphql, useStaticQuery } from 'gatsby';

const useToolboxContentHub = () => {
  const toolboxContentHub = useStaticQuery(graphql`
    query {
      allArticle: allContentfulBlog(
        sort: { fields: [publishDate], order: DESC }
        filter: {
          tags: { nin: "Covid19" }
          seo: { pageUrl: { regex: "/^(?!success-story.*$).*/g" } }
        }
        limit: 10
      ) {
        nodes {
          ...BlogCard
        }
      }
      allSuccessStory: allContentfulBlog(
        sort: { fields: [publishDate], order: DESC }
        filter: { seo: { pageUrl: { regex: "/^success-story/g" } } }
        limit: 10
      ) {
        nodes {
          ...BlogCard
        }
      }
      allIndustryInsights: allContentfulBlog(
        sort: { fields: [publishDate], order: DESC }
        filter: { seo: { taxonomy: { topics: { eq: "Industry Insights" } } } }
        limit: 10
      ) {
        nodes {
          ...BlogCard
        }
      }
      allContentfulWebinar(
        filter: {
          onDemandPage: { eq: true }
          removeFromSplashPage: { eq: false }
          seo: { taxonomy: { topics: { nin: "Covid" } } }
        }
        limit: 10
        sort: { order: DESC, fields: createdAt }
      ) {
        nodes {
          ...WebinarCard
        }
      }
      allContentfulPodcast(
        filter: { series: { eq: "Toolbox for the Trades" } }
        limit: 10
        sort: { order: DESC, fields: podcastDate }
      ) {
        nodes {
          ...PodcastCard
        }
      }
      allContentfulGatedContentPage(
        filter: { internalPageName: { ne: "Gated Content Test" } }
        sort: { order: DESC, fields: id }
        limit: 10
      ) {
        nodes {
          ...GatedContentCard
        }
      }
      allTemplates: allContentfulDownloadableContent(
        filter: { downloadableContentType: { eq: "Templates" } }
      ) {
        nodes {
          ...DownloadableContentCard
        }
      }
      allSalary: allContentfulDownloadableContent(
        filter: { downloadableContentType: { eq: "Salary Pieces" } }
      ) {
        nodes {
          ...DownloadableContentCard
        }
      }
      allLicensing: allContentfulDownloadableContent(
        filter: { downloadableContentType: { eq: "Licensing" } }
      ) {
        nodes {
          ...DownloadableContentCard
        }
      }
    }
  `);
  return toolboxContentHub;
};

export default useToolboxContentHub;
