import styled from 'styled-components';
import Select from 'components/Core/Select';
import { Tabs } from '@mui/material';

export const ResourcesSectionContainer = styled.div`
  max-width: 1299px;
  padding: 10px 68px;
  margin: 0 auto;

  .resources-section--title {
    margin-bottom: 25px;

    h2 {
      color: var(--white);
      margin: 0;
    }

    .resources-section--arrow-container {
      display: none;

      .resources-section--arrow {
        width: 35px;
        height: 35px;
        border: 1px solid var(--white);
        border-radius: 50%;
        margin-right: 15px;
        cursor: pointer;

        svg {
          width: 100%;
          height: 100%;
          padding: 10px;

          path {
            stroke: white;
          }
        }

        &.left-arrow {
          svg {
            transform: scaleX(-1);
            padding-right: 8px;
          }
        }

        &.right-arrow {
          svg {
            padding-left: 12px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .card--cover {
    background: #111111;
  }

  .resources-section--content {
    margin-bottom: 10px;
    overflow: hidden;

    .resources-section--tab-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      button,
      a {
        font-family: var(--font-family-heading);
        font-weight: var(--font-weight-900);
        color: var(--white);
        font-style: normal;
        font-size: var(--font-size-44);
        line-height: var(--line-height-110);
        letter-spacing: var(--letter-spacing--0-04);
        margin-right: 100px;
        text-transform: none;
        padding: 12px 0px;
        background: none;

        &.licensing-title {
          &:after {
            content: ' ';
            height: 4px !important;
            background: var(--titan-blue-3) !important;
            position: absolute;
            width: 166px !important;
            bottom: 0;
            left: 0;
          }
        }

        &.templates-title {
          opacity: 0.2;

          &:hover {
            opacity: 1;
          }
        }

        &:hover {
          background: none;
        }
      }
    }

    .resources-section--tab-content {
      padding: 34px 0 30px;

      .resources-section--tab-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .resources-section--tab-header-select {
          display: flex;
        }
      }
    }

    .link-view-all {
      color: var(--white);
      margin-left: 20px;

      &:hover {
        opacity: 0.5;
      }

      &.mobile-show {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .resources-section--tab-wrapper {
      button,
      a {
        font-size: var(--font-size-22) !important;
        margin-right: 20px !important;
        margin-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }

  @media (max-width: 991px) {
    padding: 10px 58px;
  }

  @media (max-width: 661px) {
    .resources-section--content {
      position: relative;

      .link-view-all {
        &.mobile-show {
          display: block;
          height: 45px;
          margin: 0 !important;
          white-space: nowrap;
          color: var(--white) !important;
          font-size: var(--font-size-mobile-p-md) !important;
          font-weight: normal !important;
          position: absolute;
          right: 0;
        }

        &.desktop-show {
          display: none;
        }
      }
    }
  }

  @media (max-width: 575px) {
    padding-left: 28px;
    padding-right: 28px;

    .resources-section--title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      /*
      //temporary hide
      .resources-section--arrow-container {
        display: flex;
      }
      */
    }

    .resources-section--content {
      .resources-section--tab-content {
        padding: 25px 0;

        .resources-section--tab-header {
          margin-bottom: 20px;

          .resources-section--tab-header-select {
            flex: 1;
            flex-direction: column;
          }
        }
      }
    }
  }
`;

export const FilterSelect = styled(Select)`
  min-width: 245px !important;
  margin-right: 20px !important;

  .MuiFilledInput-root {
    height: 56px !important;
    background: transparent !important;

    .MuiSelect-select {
      color: white;
      padding-top: 20px;
    }
    .MuiSelect-select:focus {
      background-color: transparent !important;
    }
    .MuiSvgIcon-root {
      color: var(--white);
    }
  }

  @media (max-width: 767px) {
    min-width: 200px;
  }

  @media (max-width: 575px) {
    margin-bottom: 20px !important;
    margin-right: 0 !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
`;
