import { graphql, useStaticQuery } from 'gatsby';

const useToolboxImages = () => {
  const { allContentfulImage } = useStaticQuery(graphql`
    query {
      allContentfulImage(
        filter: {
          internalName: {
            in: [
              "toolbox-mark-white"
              "webinar-mark-white"
              "podcast-mark-white"
              "blog-mark-white"
              "tools-mark-white"
              "download-mark-white"
              "toolbox-mark"
              "webinar-mark"
              "podcast-mark"
              "blog-mark"
              "tools-mark"
              "download-mark"
              "toolbox-growth-series-icon"
            ]
          }
        }
      ) {
        edges {
          node {
            ...WebsiteImage
          }
        }
      }
    }
  `);

  return allContentfulImage?.edges.reduce((prev, cur) => {
    const ret = { ...prev };
    ret[cur.node.internalName] = cur.node;
    return ret;
  }, {});
};

export default useToolboxImages;
