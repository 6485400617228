import React, { useState, useEffect } from 'react';
import HyperLink from 'components/Core/HyperLink';
import SalaryLicensesContent from './SalaryLicenses';
import Button from 'components/Core/Button';
import { ResourcesSectionContainer, FilterSelect } from './styles';

const DownloadsResourcesSection = ({
  templatePosts,
  salaryPosts,
  licensingPosts,
  ...otherProps
}) => {
  const [filter, setFilter] = useState({ industry: null, state: null });
  const [industryOptions, setIndustryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);

  const handleFilterChange = (id, value) =>
    setFilter({ ...filter, [id]: value });

  useEffect(() => {
    const industries = Array.from(
      new Set(licensingPosts?.flatMap(item => item.seo?.taxonomy?.industries?.[0]))
    )
      .filter(Boolean)
      .map(item => ({ label: item, value: item }));

    setIndustryOptions(industries);
  }, []);

  useEffect(() => {
    const filtered = licensingPosts?.filter(item =>
      item.seo?.taxonomy?.industries?.includes('All Industries') ||
      item.seo?.taxonomy?.industries?.includes(filter.industry)
    );

    const states = Array.from(new Set(filtered?.map(item => item.state)))
      .filter(Boolean)
      .sort()
      .map(item => ({ label: item, value: item }));

    setStateOptions(states);
  }, [filter.industry]);

  useEffect(() => {
    industryOptions?.length > 0 &&
      (!filter.industry || !industryOptions?.includes(filter.industry)) &&
      handleFilterChange('industry', industryOptions[0].value);
  }, [industryOptions]);

  useEffect(() => {
    stateOptions?.length > 0 &&
      (!filter.state || !stateOptions?.includes(filter.state)) &&
      handleFilterChange('state', stateOptions[0].value);
  }, [stateOptions]);

  useEffect(() => {
    const filtered = licensingPosts.filter(({ seo, state }) =>
      (seo?.taxonomy?.industries?.includes('All Industries') ||
      seo?.taxonomy?.industries?.includes(filter.industry)) &&
      state === filter.state
    );

    setSelectedPost(filtered.length > 0 ? filtered[0] : null);
  }, [filter]);

  return (
    <ResourcesSectionContainer {...otherProps}>
      <div className="resources-section--title">
        <h2 className="font-heading-lg">Downloads</h2>
      </div>
      <div className="resources-section--content">
        <div className="resources-section--tab-wrapper">
          <Button className="licensing-title">Licensing</Button>
          <Button className="templates-title" to="/templates">
            Templates
          </Button>
          <HyperLink href="/licensing" className="link-view-all mobile-show font-md">
            View All
          </HyperLink>
        </div>
        <div className="resources-section--tab-content">
          <div className="resources-section--tab-header">
            <div className="resources-section--tab-header-select">
              <FilterSelect
                id="industry"
                options={industryOptions}
                value={filter.industry}
                handleChange={handleFilterChange}
              />
              <FilterSelect
                id="state"
                options={stateOptions}
                value={filter.state}
                handleChange={handleFilterChange}
              />
            </div>
            <HyperLink href="/licensing" className="link-view-all desktop-show font-md">
              View All
            </HyperLink>
          </div>
          <div className="resources-section--tab-body">
            <SalaryLicensesContent selectedPost={selectedPost} />
          </div>
        </div>
      </div>
    </ResourcesSectionContainer>
  );
};

export default DownloadsResourcesSection;
